import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel } from '@mui/material'
import { FormikProps } from 'formik'
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import { useEffect, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import CropIcon from '@mui/icons-material/Crop';
import CloseIcon from '@mui/icons-material/Close';
import FreeCrop, { FreeCropType } from './ImageFreeCrop';
import EditIcon from '@mui/icons-material/Edit';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

interface NoCrop {
    iscrop: false
}

interface FixedCrop {
    iscrop: true,
    type: 'fixed',
    aspect: number
}

interface props<T> {
    formik: FormikProps<T>,
    name: string,
    label: string,
    helpertext?: string,
    Icon?: React.ReactNode,
    crop: NoCrop | FixedCrop | FreeCropType
}

function FixedCrop({
    imageurl, crop, updateImage, closeCrop
}: {
    imageurl: string, crop: FixedCrop, updateImage: (b: Blob) => void, closeCrop: () => void
}) {

    const [cropData, setCropData] = useState<{ x: number, y: number }>({ x: 0, y: 0 })
    const [zoom, setZoom] = useState<number>(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({ x: 0, y: 0, width: 0, height: 0 })

    const onCropComplete = (_croppedArea: any, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }

    const cropImage = () => {
        const image = new Image();
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (ctx) {
                canvas.width = croppedAreaPixels.width;
                canvas.height = croppedAreaPixels.height;

                ctx.fillStyle = '#ffffff'; // white color
                // ctx.fillStyle = 'rgba(255, 255, 255, 0)';
                ctx.fillRect(0, 0, canvas.width, canvas.height)

                ctx.drawImage(
                    image,
                    croppedAreaPixels.x,
                    croppedAreaPixels.y,
                    croppedAreaPixels.width,
                    croppedAreaPixels.height,
                    0,
                    0,
                    croppedAreaPixels.width,
                    croppedAreaPixels.height
                );
                canvas.toBlob((blob) => {
                    if (blob) {
                        updateImage(blob)
                        closeCrop()
                    }
                }, 'image/jpeg');
            }
        };
        image.src = imageurl;
    };


    return (
        <>
            <DialogContent>
                <div className='fixed-cropper' style={{ display: 'flex', }}>
                    <Cropper
                        image={imageurl}
                        crop={cropData}
                        zoom={zoom}
                        aspect={crop.aspect}
                        onCropChange={setCropData}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => cropImage()} startIcon={<CropIcon />}>Crop</Button>
                <Button variant='text' onClick={closeCrop} startIcon={<CloseIcon />}>Cancel</Button>
            </DialogActions>
        </>
    )
}

export default function ImageUpload2<T extends {}>(
    {
        name, label, helpertext, formik,
        Icon = <WallpaperIcon fontSize='large' />,
        crop
    }:
        props<T>
) {
    const iid = (name as string + 'fileInput').trim()
    const nameurl = (name as string + 'url' as keyof T) as string
    const { values, handleChange, touched, errors, handleBlur, setFieldValue, ...formikProps } = formik

    const [readyToCrop, setReadyToCrop] = useState<boolean>(false)
    const [imageToCrop, setImageToCrop] = useState<string | null>(null)

    useEffect(() => {
        return () => {
            setImageToCrop(null)
        }
    }, [])

    const handleImageClick = () => {
        // Trigger file input click when the image is clicked
        const clickable = document.getElementById(iid);
        if (clickable !== null)
            return clickable.click()
        return;
    };

    const updateImage = (image: Blob) => {
        setFieldValue(name, image);
        setFieldValue(nameurl, '');

        setReadyToCrop(false)
    }

    const decideCropping = (file: Blob) => {
        if (!crop.iscrop) return updateImage(file)
        const imageurl = URL.createObjectURL(file)
        setImageToCrop(imageurl)
        setReadyToCrop(true)
    }

    return (
        <div>
            <>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: 'column', marginBottom: '40px', position: 'relative' }}>
                    <FormControl error={touched[name as keyof T] && Boolean(errors[name as keyof T])} >
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px', mb: 2, }}>
                            <label htmlFor={iid} >{label}</label>
                            <Avatar
                                alt={name.toUpperCase()}
                                src={(values[nameurl as keyof T] as string || (values[name as keyof T] ? URL.createObjectURL(values[name as keyof T] as Blob) : '')) as string}
                                sx={{ width: { xs: '100px', sm: '200px', }, height: { xs: '100px', sm: '200px' }, cursor: 'pointer', borderRadius: '20px', boxShadow: '0px 0px 5px 0px #000000' }}
                                onClick={handleImageClick}
                                onBlur={() => formik.setFieldTouched(name, true)}
                            >
                                {Icon}
                            </Avatar>
                            <input
                                type="file"
                                id={iid}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    if (!event.target.files) return;
                                    const files = event.currentTarget.files
                                    if (files?.length) {
                                        const file = files[0]
                                        decideCropping(file)
                                    }
                                }}
                                onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const files = event.currentTarget.files
                                    if (files?.length) {
                                    }
                                    formik.setFieldTouched(name, true)
                                }}
                                accept="image/png, image/jpeg, image/jpg"
                                style={{ display: 'none' }}
                            />
                        </Box>
                    </FormControl>
                    <div style={{ position: 'relative' }}>
                        <Box
                            sx={{
                                position: "absolute",
                                bottom: { xs: '20px', sm: '40px' },
                                right: { xs: 0, sm: '40px' },
                                zIndex: 99,
                            }}
                        >
                            <IconButton
                                onClick={handleImageClick}
                            >
                                <EditIcon color='error' />
                            </IconButton>
                        </Box>
                    </div>
                    {
                        imageToCrop && values[name as keyof T] &&
                        <IconButton onClick={() => setReadyToCrop(true)}>
                            <AspectRatioIcon color='warning' />
                        </IconButton>
                    }
                    <FormHelperText sx={{ color: ((touched[name as keyof typeof touched] as boolean) && (errors[name as keyof typeof errors] as string)) ? 'red' : 'inherit' }} >{((touched[name as keyof typeof touched] as boolean) && (errors[name as keyof typeof errors] as string)) || helpertext}</FormHelperText>
                </div>
            </>
            <Dialog
                fullScreen
                open={readyToCrop}
                onClose={() => setReadyToCrop(false)}
            >
                <DialogTitle>Crop Image</DialogTitle>
                {
                    crop.iscrop &&
                    <>
                        {
                            crop.type === 'fixed' ?
                                <FixedCrop imageurl={imageToCrop as string} crop={crop} updateImage={updateImage} closeCrop={() => setReadyToCrop(false)} />
                                : crop.type === 'free' &&
                                <FreeCrop imageurl={imageToCrop as string} cropData={crop} updateImage={updateImage} closeCrop={() => setReadyToCrop(false)} />
                        }
                    </>
                }
            </Dialog>
        </div>
    )
}
