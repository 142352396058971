'use client';

import { Dispatch, LegacyRef, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { Container, Box, Button, Typography, Alert, Backdrop, CircularProgress, Dialog, DialogContent, AppBar, Toolbar, IconButton, TextField, Grid, Paper, DialogActions, FormControl, InputLabel, Select, MenuItem, Stack } from '@mui/material';
import { collection, doc, increment, setDoc } from 'firebase/firestore';
import html2canvas from 'html2canvas';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PersonIcon from '@mui/icons-material/Person';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import { CertParticipant, CertParticipants, CertSearchData } from './Cert';
import ImageUpload2 from '../../utils/CustomElements/ImageUpload2';
import CreateFields from '../../utils/CustomElements/CreateFields';
import { db } from '../../utils/firebase/firebase';

const eventid = 'MJn5oOn8QABjvw0e7OnO'
const collectionRef = collection(db, 'events', 'participants', eventid)
const rateurl = 'https://g.page/r/CZhOudGOPTfmEBM/review'
const certurl = 'https://firebasestorage.googleapis.com/v0/b/bizsnap-app.appspot.com/o/cert%2Ffinal%20cert.jpg?alt=media&token=e0eff82e-54ed-412e-ac47-a8b98e43d49a'

const imageBlobToURL = (blob: Blob | null) => blob ? URL.createObjectURL(blob) : ''

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
        color: '#ff3d47',
    },
});

function GenCertImagew({
    templateData, bizRef, rawImg, onLoadRawImg
}: {
    templateData: CertParticipant,
    bizRef: LegacyRef<HTMLDivElement>,
    rawImg: string,
    onLoadRawImg: () => void
}) {

    return (
        <div
            style={{ position: 'relative' }}
        >
            <div
                className="biz-img-container-to-hide"
                style={{
                    position: 'absolute',
                    top: -99999999
                }}
            >
                <div
                    className="biz-img-container"
                    style={{ position: 'relative', height: 1130 + 'px', width: 1600 + 'px' }}
                    ref={bizRef}
                >
                    <div
                        className="biz-img-body"
                        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    >
                        <img
                            src={rawImg}
                            alt={'Boostify-Biz'}
                            onLoad={onLoadRawImg}
                            style={{ width: 1600 + 'px', height: 1130 + 'px', objectFit: 'cover' }}
                        />
                    </div>
                    <div className="biz-image-name">
                        <div
                            style={{
                                position: 'absolute',
                                top: '42%',
                                left: '32%',
                                width: '100%',
                                textAlign: 'left',
                                fontSize: '2em',
                                color: 'black',
                                textShadow: '0px 0px 5px white'
                            }}
                        >
                            {(templateData.n).toUpperCase()}
                        </div>
                    </div>
                    <div className="biz-image-name">
                        <div
                            style={{
                                position: 'absolute',
                                top: '50.75%',
                                left: '50%',
                                width: '100%',
                                textAlign: 'left',
                                fontSize: '1.7em',
                                color: 'black',
                                textShadow: '0px 0px 5px white',
                                fontWeight: 'bold'
                            }}
                        >
                            {((templateData.e || 'w') === 'w') ? 'Walkathon' : 'Marathon'} 5 KM
                        </div>
                    </div>
                    {
                        templateData.img &&
                        <div className="biz-image-profile-image">
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '22%',
                                    right: '10%',
                                }}
                            >
                                <img src={templateData.img} alt='temp image' width={'200px'} height={'200px'} />
                            </div>
                        </div>
                    }
                    <div
                        className="biz-img-footer"
                        style={{ textAlign: 'center', fontSize: 'smaller', width: '100%', position: 'absolute', bottom: '10px' }}
                    >
                        <div className="copyright" style={{ fontSize: '10px', color: 'black' }}>
                            All Rights Reserved. © 2024 to www.Boostify.Biz
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


function SelectParticipant({ participants, setParticipant, openSelect, setOpenSelect, setCertificate }: { participants: CertParticipants, setParticipant: Dispatch<SetStateAction<CertParticipant>>, openSelect: boolean, setOpenSelect: (open: boolean) => void, setCertificate: Dispatch<SetStateAction<{ url: string | null, blob: Blob | null }>> }) {
    const [searchString, setSearchString] = useState('');

    const searchResultParticipants = useMemo(() => {
        if (!searchString)
            return participants
        return Object.keys(participants).filter((key) => participants[key].n.toLowerCase().includes(searchString.toLowerCase()))
            .reduce((acc, key) => ({ ...acc, [key]: participants[key] }), {})
    }, [participants, searchString])

    return (
        <>
            <Container maxWidth="sm">
                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{ mt: 4 }}
                    onClick={() => {
                        setOpenSelect(true)
                    }}
                >
                    Select Another Participant
                </Button>
            </Container>
            <Dialog fullScreen open={openSelect}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setOpenSelect(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Select Participant
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <TextField
                        label="Search Participant"
                        fullWidth
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        helperText="Search by name"
                    />
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {Object.keys(searchResultParticipants).map((key) => (
                            <Grid item xs={12} sm={12} md={6} lg={4} key={key}>
                                <Paper sx={{ p: 2, textAlign: 'center' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {participants[key].n}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {participants[key].e === 'm' ? 'Marathon' : 'Walkathon'}
                                    </Typography>
                                    <Button size="small" variant='outlined' color="success"
                                        onClick={() => {
                                            setParticipant((prevState) => ({ ...prevState, ...participants[key], id: key, docid: prevState.docid }))
                                            setCertificate({ url: null, blob: null })
                                            setOpenSelect(false)
                                        }}
                                    >
                                        Select
                                    </Button>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}


export default function CertDirectDownloadPage() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [participant, setParticipant] = useState<CertParticipant>({} as CertParticipant);
    const [participants, setParticipants] = useState<CertParticipants>({});
    const [readyToGen, setReadyToGen] = useState(false);
    const [orgCert, setOrgCert] = useState<string | null>(null);
    const [certificate, setCertificate] = useState<{ url: string | null, blob: Blob | null }>({ url: null, blob: null });
    const [openSelect, setOpenSelect] = useState(false);

    const resetAll = () => {
        setParticipant({} as CertParticipant);
        setParticipants({});
        setReadyToGen(false);
        setCertificate({ url: null, blob: null });
        setOpenSelect(false);
        setReadyToGen(false);
        formik.resetForm();
        setError('');
        setSuccess('');
    }

    const formik = useFormik<CertSearchData>({
        initialValues: {
            mobileNo: '',
            image: null,
        },
        onSubmit: () => {
            onSubmit()
        },
        validationSchema: yup.object().shape({
            mobileNo: yup.string().required('Please enter your Mobile Number')
                .test('len', 'Please enter a valid Mobile Number', val => val?.length === 10)
                .test('num', 'Please enter a valid Mobile Number', val => !isNaN(Number(val)))
                .test('regex', 'Please enter a valid Mobile Number', val => /^[6-9]\d{9}$/.test(val || '')),
        }),
    })

    const bizRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const fetchCert = async () => {
            setLoading(true);
            const imageResponse = await fetch(`${certurl}`);
            if (!imageResponse.ok) {
                throw new Error('Failed to fetch certificate image');
            }
            const imageBlob = await imageResponse.blob();
            const imageUrl = URL.createObjectURL(imageBlob);
            setOrgCert(imageUrl);
            setLoading(false);
        }

        fetchCert();

        return () => {
            setLoading(false);
        }

    }, [])

    const onSubmit = async () => {
        const { mobileNo } = formik.values
        if (!mobileNo)
            return setError('Please fill in mobile number.');

        setError('');
        setSuccess('');
        setLoading(true);
        setParticipant({} as CertParticipant);
        setParticipants({});

        try {
            const enmobile = Number(mobileNo).toString(36)
            const ranid = Number(new Date()).toString(36)
            await setDoc(doc(collectionRef, enmobile), { [ranid]: { dir: true, n: '', e: 'w' } }, { merge: true })
            setParticipant({ docid: enmobile, id: ranid, n: '', e: 'w' } as CertParticipant)
            setSuccess('Certificate found! Downloading...');
            setError('');
        } catch (error) {
            console.log(error);
            setError('Failed to fetch certificate. Please check the details and try again.');
        }
        finally {
            setLoading(false);
        }
    };

    const onLoadImage = async () => {
        await html2canvas(bizRef.current as HTMLDivElement, { width: 1600, height: 1130 })
            .then(canvas => {
                canvas.toBlob(
                    (blob) => {
                        const downloadLink = document.createElement('a');
                        const url = URL.createObjectURL(blob as Blob);
                        downloadLink.href = url;
                        setCertificate({ url, blob });
                        downloadLink.download = `${participant?.n as string || 'VYBS'}.jpg`;
                        downloadLink.click();
                        setSuccess('Certificate downloaded successfully!');
                        if (participant?.id && participant?.docid)
                            setDoc(doc(collectionRef, participant.docid), { [participant.id as string]: { dw: increment(1) } }, { merge: true })
                        setError('');
                        setParticipants((prevState) => {
                            if (Object.keys(prevState).length < 2)
                                return {} as CertParticipants;
                            if (!participant?.id) return prevState;
                            const newState = { ...prevState };
                            delete newState[participant.id as string];
                            return newState;
                        })
                        formik.resetForm();
                    },
                    'image/jpeg',
                );
            })
            .catch(err => {
                console.error('Error on Load Raw IMG', err);
            })
            .finally(() => {
                setLoading(false);
                setReadyToGen(false);
                setParticipant(prevState => ({ docid: prevState.docid, id: prevState.id } as CertParticipant));
                if (Object.keys(participants).length < 2)
                    setParticipants({})
            })
    }

    const onAgainDownload = (by: 'byimg' | 'byshare' | 'bypdf' = 'byimg') => {
        if (participant?.id && participant?.docid)
            setDoc(doc(collectionRef, participant.docid), { [participant.id as string]: { [by]: increment(1) } }, { merge: true })
    }

    if (readyToGen && participant.n) {
        return (<>
            <GenCertImagew
                bizRef={bizRef}
                onLoadRawImg={onLoadImage}
                rawImg={orgCert as string}
                templateData={participant as CertParticipant}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>)
    }

    return (
        <>
            {
                (!participant?.n && !!Object.keys(participants).length) &&
                <SelectParticipant participants={participants} setParticipant={setParticipant} openSelect={openSelect} setOpenSelect={setOpenSelect} setCertificate={setCertificate} />
            }
            {
                participant?.id && !readyToGen && !certificate.url &&
                <Dialog
                    open={true}
                >
                    <DialogContent>
                        <Typography variant="h6" gutterBottom>
                            Participant Details
                        </Typography>
                        <Box>
                            <TextField
                                label="Name"
                                fullWidth
                                value={participant.n}
                                onChange={(e) => setParticipant({ ...participant, n: e.target.value })}
                                margin='dense'
                            />
                            <FormControl fullWidth sx={{ mb: 1, mt: 1 }}>
                                <InputLabel id="event">Event</InputLabel>
                                <Select
                                    label="Event"
                                    value={participant.e}
                                    onChange={(e) => setParticipant({ ...participant, e: e.target.value as 'm' | 'w' })}
                                    name="event"
                                    id="event"
                                    sx={{ mb: 1 }}
                                >
                                    <MenuItem value="w">Walkathon</MenuItem>
                                    <MenuItem value="m">Marathon</MenuItem>
                                </Select>
                            </FormControl>
                            <ImageUpload2 formik={formik} label='Upload Image' name='image' crop={{ iscrop: true, type: 'free', aspect: 1, scale: 1, rotate: 0, isscale: true }} Icon={<PersonIcon fontSize='large' />} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 2 }}
                            onClick={async () => {
                                await setDoc(doc(collectionRef, participant.docid), { [participant.id as string]: { n: participant.n, e: participant.e } }, { merge: true })
                                setParticipant(prevStarte => ({ ...prevStarte, img: imageBlobToURL(formik.values.image) }))
                                setReadyToGen(true)
                            }}
                            disabled={!participant.n}
                        >
                            Generate Certificate
                        </Button>
                        <Button
                            variant="text"
                            color="error"
                            fullWidth
                            sx={{ mt: 2 }}
                            onClick={() => resetAll()}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            <Container maxWidth="sm">
                {
                    certificate.url ?
                        <Paper elevation={3} sx={{ p: 2, mt: 4, mb: 4 }}>
                            <Box sx={{ mt: 4, textAlign: 'center' }}>
                                <Typography variant="h4" gutterBottom>
                                    Download Your Certificate
                                </Typography>
                                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                                    <img src={certificate.url as string} alt='certificate' width='100%' />
                                </Box>
                                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                                    <IconButton color='success'
                                        onClick={() => {
                                            const downloadLink = document.createElement('a');
                                            downloadLink.href = certificate.url as string;
                                            downloadLink.download = `${participant?.n || 'certificate'}.jpg`;
                                            onAgainDownload();
                                            downloadLink.click();
                                        }}
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                    <IconButton
                                        color="primary"
                                        onClick={async () => {
                                            // Check if the browser supports the navigator.share API
                                            if (!navigator.share) {
                                                alert("Share not supported in your browser");
                                                return;
                                            }

                                            // Check if the browser can share files
                                            if (!navigator.canShare) {
                                                alert("Your browser doesn't support file sharing");
                                                return;
                                            }

                                            try {
                                                // Convert the Blob to a File object and define a MIME type
                                                const image = new File([certificate.blob as Blob], `${participant?.n || "certificate"}.jpg`, {
                                                    type: "image/jpeg",
                                                });

                                                // Check if the browser can share this specific file type
                                                if (!navigator.canShare({ files: [image] })) {
                                                    alert("Your browser can't share this file type");
                                                    return;
                                                }

                                                // Share the file using the Web Share API
                                                await navigator.share({
                                                    title: "Certificate",
                                                    text: "Here is your certificate!",
                                                    files: [image],
                                                });

                                                // Call the function to log the download method
                                                onAgainDownload("byshare");
                                            } catch (error) {
                                                console.error("Error sharing the certificate:", error);
                                                alert("Sharing failed. Please try again.");
                                            }
                                        }}
                                    >
                                        <ShareIcon />
                                    </IconButton>
                                </Stack>
                                <Button sx={{ textAlign: 'center', mt: 2 }} variant="text" color="warning" onClick={resetAll}>Reset</Button>
                            </Box>
                            <Paper sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', m: 4, p: 4 }} elevation={12}>
                                <Typography component="legend">Rate Your Experience</Typography>
                                <Box>
                                    <StyledRating
                                        name="customized-color"
                                        defaultValue={5}
                                        getLabelText={(value: number) => `${value} Heart${value !== 1 ? 's' : ''}`}
                                        icon={<FavoriteIcon fontSize="inherit" />}
                                        emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                                    />
                                </Box>
                                <Box>
                                    <Button onClick={() => window.open(rateurl, '_blank')} size='small' color='success' variant='contained' startIcon={<FavoriteBorderIcon />} sx={{ mt: 2 }}>Rate</Button>
                                </Box>
                                {/* <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>A Product of Noyyal Technologies</Typography> */}
                            </Paper>
                        </Paper>
                        :
                        <Box sx={{ mt: 8, textAlign: 'center' }}>
                            <Typography variant="body2" color={'text.secondary'} gutterBottom>
                                District Level Marathon & Walkathon For Cancer Awarness - Oct 2, 2024
                            </Typography>
                            <Typography variant="h4" gutterBottom>
                                Download Your Certificate
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                Enter your mobile number to get your certificate.
                            </Typography>
                            <Box sx={{ mt: 4 }}>
                                <form onSubmit={formik.handleSubmit} onReset={() => formik.resetForm()}>
                                    <CreateFields
                                        formik={formik}
                                        fields={[
                                            { name: 'mobileNo', label: 'Mobile No', mandatory: true },
                                        ]}
                                    />
                                    {error && (
                                        <Alert severity="error" sx={{ mt: 3 }}>
                                            {error}
                                        </Alert>
                                    )}

                                    {success && (
                                        <Alert severity="success" sx={{ mt: 3 }}>
                                            {success}
                                        </Alert>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        sx={{ mt: 4 }}
                                        // onClick={handleDownload}
                                        type='submit'
                                    >
                                        Download Certificate
                                    </Button>
                                    <Button type="reset" variant="outlined" color="warning" sx={{ mt: 2 }}>Reset</Button>
                                </form >
                            </Box>
                        </Box>
                }
            </Container>
            {
                loading &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </>
    );
}
