import { useState, useRef } from 'react'
import { Box, Button, DialogActions, DialogContent, IconButton, Slider, Stack } from '@mui/material'
import CropIcon from '@mui/icons-material/Crop';
import CloseIcon from '@mui/icons-material/Close';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
    convertToPixelCrop,
} from 'react-image-crop'
// import { canvasPreview } from './canvasPreview'
// import { useDebounceEffect } from './useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

export interface FreeCropType {
    iscrop: true,
    type: 'free',
    isscale: boolean,
    scale: number,
    aspect: number,
    rotate: number,
}

export default function FreeCrop({
    imageurl, cropData, updateImage, closeCrop
}: {
    imageurl: string, cropData: FreeCropType, updateImage: (b: Blob) => void, closeCrop: () => void
}) {

    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(cropData.scale)
    const [rotate, setRotate] = useState(cropData.rotate)
    const [aspect, setAspect] = useState<number | undefined>(cropData.aspect)

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    async function onDownloadCropClick() {
        try {
            const image = imgRef.current;
            if (!image) throw new Error('No image');

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d')
            if (!ctx) throw new Error('No 2d context');

            const scaleX = image.naturalWidth / image.width
            const scaleY = image.naturalHeight / image.height
            // devicePixelRatio slightly increases sharpness on retina devices
            // at the expense of slightly slower render times and needing to
            // size the image back down if you want to download/upload and be
            // true to the images natural size.
            // const pixelRatio = window.devicePixelRatio
            // As working with the devicePixelRatio, render become deadly slow in ios devices
            // so choosing the default pixelRatio as 1
            const pixelRatio = 1
            const crop = completedCrop

            canvas.width = Math.floor((crop?.width ?? 0) * scaleX * pixelRatio)
            canvas.height = Math.floor((crop?.height ?? 0) * scaleY * pixelRatio)

            ctx.scale(pixelRatio, pixelRatio)
            ctx.imageSmoothingQuality = 'high'

            const cropX = (crop?.x ?? 0) * scaleX
            const cropY = (crop?.y ?? 0) * scaleY

            const TO_RADIANS = Math.PI / 180
            const rotateRads = rotate * TO_RADIANS
            const centerX = image.naturalWidth / 2
            const centerY = image.naturalHeight / 2

            ctx.save()

            // 5) Move the crop origin to the canvas origin (0,0)
            ctx.translate(-cropX, -cropY)
            // 4) Move the origin to the center of the original position
            ctx.translate(centerX, centerY)
            // 3) Rotate around the origin
            ctx.rotate(rotateRads)
            // 2) Scale the image
            ctx.scale(scale, scale)
            // 1) Move the center of the image to the origin (0,0)
            ctx.translate(-centerX, -centerY)
            ctx.drawImage(
                image,
                0,
                0,
                image.naturalWidth,
                image.naturalHeight,
                0,
                0,
                image.naturalWidth,
                image.naturalHeight,
            )

            // ctx.restore()

            // Convert the canvas to a blob and use the updateImage function to handle it
            canvas.toBlob((blob) => {
                if (blob) {
                    updateImage(blob);
                }
            }, 'image/png', 1);
        }
        catch (err) {
            console.error(err)
            alert('Cropping Failed')
        }
    }



    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined)
        } else {
            setAspect(16 / 9)

            if (imgRef.current) {
                const { width, height } = imgRef.current
                const newCrop = centerAspectCrop(width, height, 16 / 9)
                setCrop(newCrop)
                // Updates the preview
                setCompletedCrop(convertToPixelCrop(newCrop, width, height))
            }
        }
    }

    function handleRotateClick() {
        const options = [0, 90, 180, -90]
        const currentIndex = options.indexOf(rotate)
        const newIndex = (currentIndex + 1) % options.length
        setRotate(options[newIndex])
    }

    return (
        <>
            <DialogContent>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <Box
                        sx={{ maxWidth: { xs: '75%', md: '50%' } }}
                    >
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                        // minHeight={100}
                        // circularCrop
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imageurl}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    </Box>
                </div>
            </DialogContent>
            <DialogActions sx={{ flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    Rotate
                    <IconButton onClick={handleRotateClick} color='primary' size='small'>
                        <CropRotateIcon />
                    </IconButton>
                </Box>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center" ml={5} mr={5} width={"80%"}>
                    Resize
                    <AspectRatioIcon />
                    <Slider
                        value={scale}
                        min={0.1}
                        max={2}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => setScale(Number(zoom))}
                        classes={{ root: "slider" }}
                    />
                </Stack>
            </DialogActions>
            <DialogActions>
                <Button variant='contained' color='secondary' onClick={onDownloadCropClick} startIcon={<CropIcon />}>Crop</Button>
                <Button variant='text' color='error' onClick={closeCrop} startIcon={<CloseIcon />}>Cancel</Button>
            </DialogActions>
        </>
    )
}
