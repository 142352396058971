import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, SvgIconProps, TextField } from "@mui/material"
import { FormikProps } from "formik"
import { HTMLInputTypeAttribute } from "react"
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MapIcon from '@mui/icons-material/Map';
import BusinessIcon from '@mui/icons-material/Business';

type icons = 'mobile' | 'mail' | 'web' | 'wa' | 'fb' | 'insta' | 'pin' | 'yt' | 'li' | 'x' | 'map' | 'addr'

export type fieldTypes<T extends {}> = {
    name: keyof T,
    f?: 't' | 's' | 'c' | 'fc' | 'ic',
    type?: HTMLInputTypeAttribute | undefined,
    label: string,
    placeholder?: string,
    mandatory?: boolean
    helpertext?: string,
    startAdorment?: boolean,
    endAdorment?: boolean,
    startAdormentIcon?: icons,
    endAdormentIcon?: icons,
    multiline?: boolean,
    rows?: number,
}

export type fieldsTypes<T_Values extends {}> = Array<fieldTypes<T_Values>>

type createFieldsType<T_Values extends {}> = { fields: fieldsTypes<T_Values>, formik: FormikProps<T_Values> }
type createFieldType<T_Values extends {}> = { field: fieldTypes<T_Values>, formik: FormikProps<T_Values>, index?: number, }


const adormentIcon: { [key: icons | string]: React.ReactElement<SvgIconProps> } = {
    mobile: <CallIcon />,
    mail: <EmailIcon />,
    web: <LanguageIcon />,
    wa: <WhatsAppIcon />,
    fb: <FacebookIcon />,
    insta: <InstagramIcon />,
    pin: <PinterestIcon />,
    yt: <YouTubeIcon />,
    li: <LinkedInIcon />,
    x: <XIcon />,
    default: <OpenInNewIcon />,
    map: <MapIcon />,
    addr: <BusinessIcon />
}

const CreateField = <T_Values extends ({})>({ formik, field, index }: createFieldType<T_Values>) => {

    const { f = 't', name, placeholder, helpertext, label, mandatory, type = 'text' } = field

    const isIndexBased = !isNaN(index as number)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isIndexBased) return formik.handleChange(event)
        const { value } = event.target;
        return formik.setFieldValue(`${name as string}.${index}`, value)
    };

    // const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    //     if (!isIndexBased) return formik.handleChange(event)
    //     const { value } = event.target;
    //     return formik.setFieldValue(`${name as string}.${index}`, value)
    // };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!isIndexBased) return formik.handleBlur(event)
        return formik.setFieldTouched(`${name as string}.${index}`, true)
    };

    const isTouched = formik.touched[name as keyof typeof formik.touched]
    const errorMessage = formik.errors[name as keyof typeof formik.errors]

    // const value = isIndexBased ? formik.values[name as keyof typeof formik.values][index as number] : (formik.values[name as keyof typeof formik.values])
    const values = isIndexBased
        ? (formik.values[name as keyof typeof formik.values] as unknown[])
        : [];

    const value = isIndexBased
        ? (values[index as number] as unknown)
        : (formik.values[name as keyof typeof formik.values]);

    switch (f) {
        case 'fc': {
            return <FormControl variant='standard' fullWidth sx={{ mb: 3 }}>
                <InputLabel>{label}{mandatory ? '*' : ' (optional)'}</InputLabel>
                <Input
                    startAdornment={(field.startAdorment || field.startAdormentIcon) ?
                        <InputAdornment position='start'>{adormentIcon[(field.startAdormentIcon || name) as icons] ?? ''}</InputAdornment>
                        : ''
                    }
                    // endAdornment={<IconButton ><DeleteIcon color='error' /></IconButton>}
                    type={type}
                    value={value}
                    onChange={handleChange}
                    name={name as string}
                    id={name as string}
                    placeholder={placeholder}
                    sx={{ '& input': { color: isTouched && !!errorMessage ? 'red' : 'inherit' } }}
                    onBlur={handleBlur}
                    multiline={field.multiline || false}
                    rows={field.rows || 1}
                />
                <FormHelperText sx={{ color: isTouched && !!errorMessage ? 'red' : 'inherit' }}>
                    {((isTouched && errorMessage) || helpertext || '') as string}
                </FormHelperText>
            </FormControl>
        }
        case 't': default: {
            return <TextField
                label={label + (mandatory ? '*' : '(optional)')}
                type={type}
                variant='standard'
                margin='normal'
                fullWidth
                name={name as string}
                id={name as string}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                error={isTouched && Boolean(errorMessage)}
                helperText={(isTouched && Boolean(errorMessage)) ? errorMessage as string : (helpertext || '')}
                autoComplete='off'
                placeholder={placeholder}
                sx={{ mb: 1 }}
                multiline={field.multiline || false}
                rows={field.rows || 1}
            />
        }
    }
}

export default function CreateFields<T_Values extends {}>(
    {
        fields, formik
    }: createFieldsType<T_Values>
) {
    return (
        <>
            {
                fields.map(field => {
                    if (Array.isArray(formik.values[field.name]))
                        return (formik.values[field.name] as []).map((_, index) => <CreateField<T_Values> key={field.name as string} field={field} formik={formik} index={index} />)
                    return <CreateField<T_Values> key={field.name as string} field={field} formik={formik} />
                })
            }
        </>
    )
}
